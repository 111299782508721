import React, { Fragment } from "react";
import styled from "styled-components";

const NavWrapper = styled.header`
  padding: 0.5rem 1rem;
  flex: none;
  background: rgba(9, 30, 66, 0.36);
  text-align: center;
`;

const Title = styled.div`
color: white;
font-size 17pt;
font-weight: bold
`;
const SubTitle = styled.div`
  color: white;
  font-size: 10pt;
  text-decoration: underline;
  text-decoration-color: white;
`;

interface Props {
  title: string;
  showVoteLink: boolean;
}

export const NavBar = ({ title, showVoteLink }: Props) => {
  return title ? (
    <NavWrapper>
      <Title>{title}</Title>
      <a
        href="http://go.atlassian.com/vote"
        target="_blank"
        rel="noopener noreferrer"
      >
        {showVoteLink && <SubTitle>go/vote</SubTitle>}
      </a>
    </NavWrapper>
  ) : (
    <Fragment />
  );
};
