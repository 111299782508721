import React from "react";
import { NavBar } from "./components/NavBar/NavBar";
import styled, { keyframes } from "styled-components";
import shipitLogoImg from "static/img/shipit_logo.png";

const SHIP_IT_BACKGROUND_IMAGE =
  "https://remotereactions.com/shipit-background.png";

const Main = styled.main`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const Overlay = styled.main`
  height: 100%;
  width: 20%;
  right: 0;
  position: absolute;
`;

const pulse = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`;

const ShipItLogo = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${shipitLogoImg});
  height: 256px;
  width: 256px;
  border-radius: 1000px;
  background-size: 256px 256px;
  margin-left: -128px;
  margin-top: -128px;
  background-position: center center;
  background-repeat: no-repeat;
  position: fixed;
  top: 50%;
  left: 50%;
  animation: ${pulse} 4s infinite;
`;

const NoLogo = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface Props {
  title: string;
  background?: string;
  overlayMode?: boolean;
}

const Background = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Layout: React.FC<Props> = ({
  title,
  children,
  background,
  overlayMode = false,
}) => {

  const isShipIt = background === SHIP_IT_BACKGROUND_IMAGE;
  const Logo = isShipIt ? ShipItLogo : NoLogo;
  const style = overlayMode || !background ? {} : { backgroundImage: `url(${background})` };

  return (
    <Background style={style}>
      {overlayMode ? (
        <Overlay>{children}</Overlay>
      ) : (
        <>
        <NavBar title={title} showVoteLink={isShipIt} />
          <Logo />
        <Main>{children}</Main>
        </>
      )}
    </Background>
  );
};
