import React, { useCallback } from "react";
import { EmojiBarView } from "./view";
import { sendMessage } from "services/messages";
import { EmojiBarProps } from "./types";
import { useRoom } from "services/rooms";
import { Room, DEFAULT_EMOJIS } from "services/rooms/types";
import GraphemeSplitter from "grapheme-splitter";

const splitter = new GraphemeSplitter();

export const EmojiBar = React.memo(({ roomId }: EmojiBarProps) => {
  const noop = useCallback(() => {}, []);

  const room: Room | null = useRoom(roomId, noop, noop);
  const emojiString =
    room === null || room.emojis === undefined ? DEFAULT_EMOJIS : room.emojis;
  let emojiOptions = [] as string[];
  emojiOptions = splitter.splitGraphemes(emojiString);

  const noopErrorLog = useCallback((error) => {
    console.log(error);
  }, []);
  return (
    <EmojiBarView
      emojiOptions={emojiOptions}
      onClickEmoji={(emoji) => sendMessage(roomId, emoji, noopErrorLog)}
    />
  );
});
