export const isOverlay = (): boolean => {
  let overlay;
  try {
    const params = new URLSearchParams(window.location.search);
    overlay = Boolean(params.get("o"));
  } catch (e) {
    overlay = false;
  }
  return overlay;
};
