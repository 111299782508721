export interface Room {
  id: string;
  title: string;
  backgroundImageUrl?: string;
  emojis?: string;
  createdAt: string;
  emojiOptions: [];
}

export const DEFAULT_EMOJIS = "😂😍👏😮👍😢";
