import { database } from "../../firebase";
import { FirebaseCollections } from "../../common/constants";
import { Message, NewMessage } from "./types";
import { useCollection } from "../../utils/use-collection";

const messagesPathBuilder = (roomId: string) =>
  `${FirebaseCollections.Rooms}/${roomId}/${FirebaseCollections.Messages}`;

export const sendMessage = async (
  roomId: string,
  b: string,
  onError: (error: Error) => void = () => {}
) => {
  const message: NewMessage = {
    b
  };
  try {
    await database
      .ref(`${messagesPathBuilder(roomId)}/${Date.now()}`)
      .set(message);
  } catch (error) {
    console.log(`created Message: error`);
    onError(error);
  }
};

export const useMessages = (roomId: string) =>
  useCollection<Message>(messagesPathBuilder(roomId));
