import React from "react";

import Spinner from "@atlaskit/spinner";

import { CenterContent } from "components/Layout/components/CenterContent/CenterContent";

export const Loading = () => (
  <CenterContent>
    <Spinner />
  </CenterContent>
);
