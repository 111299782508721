import { EmojiBarContainer, EmojiIcon } from "./styled";
import React from "react";
import { EmojiBarViewProps } from "./types";

export const EmojiBarView = ({
  emojiOptions,
  onClickEmoji,
}: EmojiBarViewProps) => {
  return (
    <EmojiBarContainer>
      {emojiOptions.map((emoji) => (
        <EmojiIcon key={emoji} onClick={() => onClickEmoji(emoji)}>
          {emoji}
        </EmojiIcon>
      ))}
    </EmojiBarContainer>
  );
};
