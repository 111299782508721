import { useLayoutEffect, useState } from "react";
import { database } from "../../firebase";

export const useDocument = <T>(
  path: string | null,
  onError: (error: Error) => void,
  onFirstLoad: () => void
) => {
  const [document, setDocument] = useState<T | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  useLayoutEffect(() => {
    if (!path) {
      setDocument(null);
      return;
    }
    const ref = database.ref(path);

    ref.on("value", (snapshot) => {
      const data: T = snapshot.val();
      if (!loaded) {
        setLoaded(true);
        onFirstLoad();
      }
      setDocument(data);
    });

    return () => {
      ref.off();
    };
  }, [path, onError, loaded, onFirstLoad]);
  return document;
};
