import { useDocument } from "../../utils/use-document";
import { Room } from "./types";
import { FirebaseCollections } from "../../common/constants";
import { database } from "../../firebase";

export const useRoom = (
  roomId: string | null,
  onError: (error: Error) => void,
  onFirstLoad: () => void
) => {
  return useDocument<Room>(
    `${FirebaseCollections.Rooms}/${roomId}/data`,
    onError,
    onFirstLoad
  );
};

export const createOrUpdateRoom = async (
  roomId: string,
  room: Room,
  onError: (error: Error) => void = () => {}
) => {
  try {
    await database.ref(`${FirebaseCollections.Rooms}/${roomId}/data`).set(room);
  } catch (error) {
    console.log(`update Room: error`);
    onError(error);
  }
};
