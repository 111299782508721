import * as firebase from "../node_modules/firebase";

const config = {
  apiKey: "AIzaSyC9VfwNjyDeU6XUMNAfD9ksI3Y7PoidmcQ",
  authDomain: "reaction-rooms.firebaseapp.com",
  databaseURL: "https://reaction-rooms.firebaseio.com",
  projectId: "reaction-rooms",
  storageBucket: "reaction-rooms.appspot.com",
  messagingSenderId: "80004049383",
  appId: "1:80004049383:web:6dcd4cde42ccbfb949b542",
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const database = firebase.database();
