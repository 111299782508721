import { useLayoutEffect, useState } from "react";
import { database } from "../../firebase";

export const useCollection = <T>(path: string) => {
  const [collection, setCollection] = useState<T[]>([]);
  useLayoutEffect(() => {
    const ref = database.ref(path).limitToLast(10);
    ref.on("value", (value) => {
      const data = value.val();
      if (!data) {
        setCollection([]);
      } else {

        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            data[key].createdAt = Number(key);
            data[key].id = key;
          }
        }
        const documents: T[] = Object.values(data);
        setCollection(documents);
      }
    });
    return () => {
      ref.off();
    };
  }, [path]);
  return collection;
};

export interface Filter {
  field: string;
  comparator: any;
  value: any;
}
