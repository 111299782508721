//TODO: add pages here
import React from "react";
import { Loading } from "components";
import loadable from "@loadable/component";

const pages = {
  NotFound: loadable(() => import("./NotFound"), {
    fallback: <Loading />,
  }),
  Home: loadable(() => import("./Home"), {
    fallback: <Loading />,
  }),
  Admin: loadable(() => import("./Admin"), {
    fallback: <Loading />,
  }),
};

export default pages;
