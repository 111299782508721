import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import pages from "./pages";
import { isOverlay } from "common/globalParams";

function App() {
  const GlobalStyle = createGlobalStyle`
  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  body {
    overflow: hidden;
    height: 100%;
    ${isOverlay() ? "background-color: rgb(0,0,0,0)" : ""}
  }
`;

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Redirect exact path="/" to="/global" />
          <Route exact path="/:roomId/admin" component={pages.Admin} />
          <Route exact path="/:roomId" component={pages.Home} />
          <Route component={pages.NotFound} />
        </Switch>
      </Router>

      <GlobalStyle />
    </React.Fragment>
  );
}

export default App;
