import styled from "styled-components";

export const EmojiBarContainer = styled.div`
  display: flex;
  background: white;
  padding: 12px 8px;
  justify-content: center;
  border-radius: 72px;
  margin-bottom: 1.5rem;
  z-index: 99;
`;

export const EmojiIcon = styled.div`
  user-select: none;
  font-size: 30px;
  line-height: 1;
  padding: 0px 8px;
  cursor: pointer;
  transition: transform 300ms ease-in-out;
  &:hover {
    transform: scale(1.3);
  }
`;
